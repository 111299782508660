//component
import Card from "../components/shared/Card";
import { Link } from "react-router-dom"; //it us used to link pages on our application rather than <a></a> to prevent the browser from reloading each time the link is requested use <a></a> as link to other site in the internet
import { FaUndo } from "react-icons/fa";

function About() {
  return (
    <Card>
      <div className="about">
        <h1>About This Project</h1>
        <p>This is a React app to leave review about out service or product </p>
        <p>Version: 1.0.0</p>
        <p>
          <Link to="/">
            <FaUndo />
          </Link>
        </p>
      </div>
    </Card>
  );
}

export default About;
