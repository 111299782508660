import Card from "./shared/Card.jsx";
import PropTypes from "prop-types";
import { FaTimes, FaEdit } from "react-icons/fa";
import { useContext } from "react";
import FeedbackContext from "../context/FeedbackContext.js";

function FeedbackItem({ item }) {
  const { deleteFeedback, editFeedback } = useContext(FeedbackContext);

  return (
    <Card>
      <h1>hello</h1>
      <div className="num-display">{item.rating}</div>

      <button onClick={() => deleteFeedback(item.id)} className="close">
        <FaTimes color="purple" />
      </button>

      <button className="edit" onClick={() => editFeedback(item)}>
        <FaEdit color="purple" />
      </button>
      <div className="text-display">{item.text}</div>
    </Card>
  );
}

export default FeedbackItem;

FeedbackItem.prototype = {
  item: PropTypes.object.isRequired,
};

/**
 * setState method can be use directly setRating(3)
 * or as a callback
 * setRating((rev)=> rev + 1)
 *
 * there are different kind of even and catch documentation for that
 */
