import { createContext, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import FeedbackData from "../data/FeedbackData.js";

const FeedbackContext = createContext();

export function FeedbackProvider({ children }) {
  const [feedback, setFeedback] = useState(FeedbackData);

  //edit mode state
  const [feedbackEdit, setFeedbackEdit] = useState({
    item: {},
    edit: false,
  });

  const deleteFeedback = function (id) {
    if (window.confirm("Are you sure You want to delete?")) {
      setFeedback(feedback.filter((element) => element.id !== id));
    }
  };

  const addFeedback = (newFeedback) => {
    newFeedback.id = uuidv4;
    setFeedback([newFeedback, ...feedback]);
  };

  const editFeedback = (item) => {
    setFeedbackEdit({
      item: item,
      edit: true,
    });
  };

  const updateFeedback = (id, item) => {
    setFeedback(
      feedback.map((element) =>
        element.id === id ? { ...element, ...item } : element
      )
    );

    setFeedbackEdit({
      item: {},
      edit: false,
    });
  };

  return (
    // this makes the value: feedback stated globally available to all the component that will be children of the context provider
    <FeedbackContext.Provider
      value={{
        feedback,
        feedbackEdit,
        deleteFeedback,
        addFeedback,
        editFeedback,
        updateFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;
