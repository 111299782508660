//State Data
import { FeedbackProvider } from "./context/FeedbackContext.js";
// import FeedbackData from "./data/FeedbackData.js";
// import { useState } from "react"; //this is a global state as it can be use by any component
// //utitility libraries
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; //router library

//component
import AboutIconLink from "./components/AboutIconLink.jsx";
import Header from "./components/Header.jsx";
import FeedbackList from "./components/FeedbackList.jsx";
import FeedbackStats from "./components/FeedbackStats.jsx";
import FeedbackForm from "./components/FeedbackForm.jsx";
//Pages
import AboutPage from "./pages/About.jsx";

function App() {
  return (
    <FeedbackProvider>
      {/**now the feedback is available to all the routes and their children:: to use feedback context we use the hook useContext in the class of child where we want  */}
      {/* //using router as follows */}
      <Router>
        <div className="container">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header text="Hello World" />
                  <FeedbackForm />
                  <FeedbackStats />
                  <FeedbackList />
                  <AboutIconLink />
                </>
              }
            ></Route>
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </div>
      </Router>
    </FeedbackProvider>
  );
}
export default App;

/*
  //this where our new app will be
//we use function for creating component in modern react appliction
//this function return jsx - java script xml a syntaxtic sugar for html

  there are attribe in html that cant be used 
        1 class = user className
        2 for use htmlfor 
    
    //Property of a component are called props
    they are pass in to a component using it tag

    hooks a function that hook state to a react function hook are used instead of converting the function to a class. 
    they are used only at top level code and can only be use by react functions

    hooks start with use**
    
    ..router to user route we embed in the app.js file

    //Context 
    \context are use to define global state and function that manipulates those global state to avoid pass prop always
    */

/* <Route path="/post/*" element={<Post />} /> */

/* passing id througth link */
