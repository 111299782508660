//importin global context
import FeedbackContext from "../context/FeedbackContext.js";
//importing contect hook
import { useContext } from "react";

import FeedbackItem from "./FeedbackItem";

import { motion, AnimatePresence } from "framer-motion";

function FeedbackList() {
  const { feedback } = useContext(FeedbackContext);
  if (!feedback || feedback.length === 0) {
    return <div>NO LIST ITEM</div>;
  }

  return (
    <div className="feedback-list">
      <AnimatePresence>
        {feedback.map((item, key) => (
          <motion.div
            key={key}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FeedbackItem item={item} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );

  //   return (
  //     <div className="feedback-list">
  //       {feedback.map((item) => (
  //         <FeedbackItem key={item.id} item={item} handleDelete={handleDelete} />
  //       ))}
  //     </div>
  //   );
}
// import { PropTypes } from "prop-types";
// FeedbackList.prototype = {
//   feedback: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       text: PropTypes.string.isRequired,
//       rating: PropTypes.number.isRequired,
//     })
//   ),
// };

export default FeedbackList;
